// banners
import tomato_banner from "../../../assets/images/products/details/tomato-powder.png";
import tamarind_banner from "../../../assets/images/products/details/tamarind-powder.png";
import milk_banner from "../../../assets/images/products/details/milk-powder.png";
import fruit_banner from "../../../assets/images/products/details/fruit-powders 2.png";
import spray_banner from "../../../assets/images/products/details/spray-aragum.png";
import diesol_banner from "../../../assets/images/products/details/diesol-soluble-dietary-fibre-bg.png";
import fat_banner from "../../../assets/images/products/details/fat-powder 2.png";
import caseinates_banner from "../../../assets/images/products/details/caseinates 2.png";
import chess_banner from "../../../assets/images/products/details/cheese-powder 2.png";
import hvp_banner from "../../../assets/images/products/details/HVP 2.png";
import non_dairy_banner from "../../../assets/images/products/details/non-dairy-creamer 2.png";
import beverage_banner from "../../../assets/images/products/details/beverage-whitener 2.png";
import caramel_banner from "../../../assets/images/products/details/caramel-color 2.png";
import beetroots_banner from "../../../assets/images/products/details/beetroots.png";
import amla_banner from "../../../assets/images/products/details/amla-powder-bg.jpg";
import lime_banner from "../../../assets/images/products/details/lime_bg.jpg";

// product
import tomato from "../../../assets/images/products/details/tomato-powder3.png";
import tamarind from "../../../assets/images/products/details/tamarind-powder3.png";
import milk from "../../../assets/images/products/details/Untitled-design-1.png";
import fruit_img from "../../../assets/images/products/details/fruit-powders.png";
import gum from "../../../assets/images/products/details/gum-arabic.png";
import diesol from "../../../assets/images/products/details/diesol-soluble-dietary-fibre.png";
import fat from "../../../assets/images/products/details/fat-powder.png";
import caseinates from "../../../assets/images/products/details/caseinates.png";
import chess from "../../../assets/images/products/details/cheese-powder.png";
import hvp from "../../../assets/images/products/details/HVP.png";
import non_dairy from "../../../assets/images/products/details/non-dairy-creamer.png";
import beverage from "../../../assets/images/products/details/beverage-whitener.png";
import beetroot from "../../../assets/images/products/details/beetroot-powder.png";
import caramel from "../../../assets/images/products/details/caramel-color.png";
import amla from "../../../assets/images/products/details/amla-powder.jpg";
import lime from "../../../assets/images/products/details/lime.png";

export const data_details = {
  "Tomato Powder": {
    banner: tomato_banner,
    product: tomato,
    description: [
      "Being a pioneer to manufacture 100% natural Tomato Powder, Drytech’s Tomato Powder has a crisp, ripe and fresh tomato taste profile. We believe in authenticity and thereby preserve the flavor and aroma in the tomato powder intact. Our purest grade of Tomato Powder has lycopene content as high as 120mg per 100g. Our Spray Dried Tomato Powder can be used in industry segments such as convenience food and seasoning.",
    ],
    table: [
      {
        product: "Spray Dried Tomato Powder",
        industry: "Convenience Food",
        application:
          "Instant Savory Premixes-Soups, Noodles,Rice, Instant Gravy Mixes, etc.",
          span  : {
            product : 2
          }
      },
      {
        industry: "Seasoning",
        application: "Seasoning, and Dusting",
      },
    ],
    fruits: false,
    didYouKnow:
      "Almost 20 % of harvested tomatoes are utilized in processed food industry which is as high as 40 million tons.",
  },
  "Tamarind Powder": {
    didYouKnow: "Tamarind is originated in Madagascar and not in India.",
    banner: tamarind_banner,
    product: tamarind,
    description: [
      "Drytech’s Spray dried tamarind powder adds natural taste, aroma and flavor of tamarind to food products. It has typical sour and tangy taste profile that can be used in industry segments that deals with convenience foods like instant premixes and culinary seasoning.",
    ],
    table: [
      {
        span  : {
          product : 2
        },
        product: "Spray Dried Tamarind Powder",
        industry: "Convenience Food",
        application:
          "Instant Savory Premixes-Soups, Noodles,Rice, Instant Gravy Mixes, etc.",
      },
      {
        industry: "Seasoning",
        application: "Seasoning, and Dusting",
      },
    ],
    fruits: false,
  },
  "Instant Fat Filled Powder": {
    didYouKnow: "Beverage Whitener comprises of less than about 2% fats",
    banner: milk_banner,
    product: milk,
    description: [
      "Instant Fat filled Powder (IFFP) is a solution for sustainable and affordable nutritious food. It is produced by spray drying the blend of the fresh quality skim milk powder and palm based fat. It is a perfect substitute for milk based powders. It offers the same convenience and benefits at a more economic value.",
    ],
    desList: [
      "Instantly soluble",
      " Cost effective substitute to whole milk powder",
      " Fat content range – 28% Protein content ranging from – 10- 24%",
      " Milky taste profile",
      "Readily compatible for dry and wet applications",
      " Available in white & light yellow colored powder",
    ],
    table: [
      {
        product: "Instant Fat Filled Powder	",
        industry: "Beverage",
        application:
          "Reconstituted Liquid Milk",
        span : {
          product  : 6,
          industry : 2
        }
      },
      {
        application: "Beverage Premix – Coffee & Tea Whitener, Health Drink, Milk Shakes etc.",
      },
      {
        industry: "Bakery",
        application: "Breads, Cookies and Biscuits",
      },
      {
        industry: "Dairy",
        application: "Yogurt and Ice cream",
      },
      {
        industry: "Confectionery",
        application: "Chocolates and Soft Candies",
      },
      {
        industry: "Convenience Food",
        application: " Dessert and Bakery Premixes",
      },
    ],
    fruits: false,
  },
  "Fruit Powder": {
    didYouKnow:
      "Fruits are the major source of essential nutrients like potassium, dietary fibres, vitamin C, and folate.",
    banner: fruit_banner,
    product: fruit_img,
    description: [
      "Fruits are and have always been an essential part of our diet. Considering they are a good source of essential vitamins and minerals, fruits are a way to go as ingredients to produce healthy foods.",
      "Though sometimes it becomes difficult to indulge in the simple joy of fruits due to seasonality and our fast paced life, Drytech produces Spray Dried Fruit Powders which are available around the year to provide convenience to food manufacturers.",
      "We adhere to international standards for all our Fruit Powder manufacturing. The quality control starts right from the beginning of the process. We use fruit pulp which is made from a selected variety of matured fruits, free from any genetic modifications. This fruit pulp is hygienically processed to produce a natural Fruit Powder which has wholesome characteristics and nutrients of fruit, so that you can enjoy your glass of instant fruit juice or your favorite food anytime of the year.",
    ],
    table: [
      {
        product: "Mango,Banana,Orange, Pineapple,Apple,Papaya,Lime,Strawberry",
        industry: "Beverage",
        application:
          "Instant Beverage Premixes, and Instant Milkshake Premixes",
          span  : {
            product : 2
          }
      },
      {
        industry: "Convenience Food",
        application:
          "Cake Premixes, Dessert Premixes, Flavored Cream, Wafer/Biscuits, and Fruit Flavored – Breakfast Cereals",
      },
    ],
    fruits: true,
  },
  "Spray Aragum": {
    didYouKnow:
      "Spray Aragum are rich in solubility, viscosity and water binding qualities.",
    banner: spray_banner,
    product: gum,
    description: [
      "Drytech is Asia’s largest manufacturer of Spray Dried Gum Arabic.",
      "Over the years, Drytech has created sustainable sourcing network for the Gum Acacia straight from the “Gum Belt” of African Region. This ensures the quality sourcing of Gum Acacia.",
      "Drytech converts these light golden crystals into fine Spray dried Powder for convenient use as a food ingredient. The entire conversion is done with zero human intervention to maintain the highest quality standard.",
      "Our Spray Dried Gum Arabic Powder can be used in industry segments like Confectionery, Beverages, Bakery, Flavor, Pharmaceutical, Snacks & Savory, Dairy and Nutrition. We also manufacture Instant Spray Aragum which has rapid dissolution property.",
    ],
    table: [
      {
        product: "Spray Dried Gum Arabic Powder",
        industry: "Confectionery ",
        application: "Soft Candies, Chewing Gums, and Chocolate/Cake Glazing",
        span  : {
          product : 8
        }
      },
      {
        industry: "Beverages",
        application: "Beverages Emulsions",
      },
      {
        industry: "Bakery",
        application: "Cereal Bars, Bread, and Biscuits ",
      },
      {
        industry: "Flavor",
        application: "Flavor Encapsulation ",
      },
      {
        industry: "Snacks & Savoury ",
        application: "Tableting, and Syrup Emulsions",
      },
      {
        industry: "Dairy",
        application: "Seasoning, and Coatings",
      },
      {
        industry: "Nutraceutical",
        application: "Stabilizer & Thickening agent",
      },
    ],
    fruits: false,
  },
  "Diesol Soluble Dietary Fiber": {
    didYouKnow:
      "Diesol is a short term for dietary soluble that has adverse effects on regular lifestyle diseases.",
    banner: diesol_banner,
    product: diesol,
    description: [
      "Drytech transforms Gum Arabic into sustainable nourishing Super Ingredient – Diesol. It provides all natural soluble dietary fiber based on 100% Gum Arabic which provides 90% soluble fibers and has a prebiotic property. Diesol also helps to reverse the adverse effects of lifestyle diseases like Obesity, Hypertension, Diabetes, etc. It also observes the maximum acceptability in human body with almost no side effects.",
    ],
    table: [
      {
        product: "Diesol",
        industry: "Food Supplement",
        application: "High Fibre Fruit Drink.",
        span  : {
          product : 3,
          industry : 3
        }
      },
      {
        application: "High Fibre Dairy Products such as Yogurt and Ice Creams.",
      },
      {
        application: "Fibre Enriched Snacks, Energy Bars, etc.",
      },
    ],
    fruits: false,
  },
  "Fat Powder": {
    didYouKnow:
      "Avocados have 77% fat in them but it increases good cholesterol and decreases bad cholesterol which makes it preferred choice for healthy food despite of its fat content.",
    banner: fat_banner,
    product: fat,
    description: [
      "Processed food is an ideal example of combination of science and imagination. To nurture creativity and bring flexibility in food products, Drytech produces Fat Powder with diverse range of protein and fat content. Unlike traditional fats, our Fat Powder offers flexibility and convenience to experiment and create unique food products to keep your customers delighted. It enhances the sensory profile and also improves texture, taste and mouth feel while extending the shelf life of a product. This is only possible due to precision and high standards maintained during the manufacturing process. They are manufactured from fully refined deodorized and selective grades of different oils such as Palm Fat, Corn Fat, HOSO Fat, Groundnut Fat, Coconut Fat, MCT Fat, Flaxseed Fat, EPRO FAT, Soya bean Fat and Canola Fat. We produce fat powders with fat loading ranging from 24% upto 80%.",
    ],
    table: [
      {
        product:
          "Hydrogenated Palm Fat Corn Fat HOSO Fat Groundnut Fat Coconut Fat MCT Fat Flaxseed Fat Soyabean Fat EPRO FAT Canola Fat",
        industry: "Convenience Food",
        application: "Soup Premixes, Cake Premixes, and Dessert Premixes",
        span  : {
          product : 5
        }
      },
      {
        industry: "Beverages",
        application: "Hot & Cold Beverages, Shakes and Drinks",
      },
      {
        industry: "Baby Food",
        application: "Infant Food Formula",
      },
      {
        industry: "Health Products",
        application:
          "Clinical Nutrition, Sports Drinks Beverages, and Health Food",
      },
      {
        industry: "Seasoning",
        application: "Creamy Base in Soup Premixes, and Culinary Seasoning",
      },
    ],
    fruits: false,
  },
  Caseinate: {
    didYouKnow:
      "The Casein Protein constitutes almost 80% of total milk protein.",
    banner: caseinates_banner,
    product: caseinates,
    description: [
      "Boost your workout session with added benefits of protein shakes. The right intake of protein fulfills the nutrition requirement and also promotes the muscle growth. The one essential protein which goes in most of protein shakes is Casein. Drytech produces stable and quality caseinates which are easily soluble and contributes to the health. Each stage is closely monitored during the manufacturing of caseinates. Right from the procurement of quality casein to testing the products at each stage, we ensure best quality of caseinates. Built on our experience and expertise, we continue to develop new techniques allowing us to make the most of this milk protein.",
    ],
    table: [
      {
        product: "Sodium Caseinate",
        industry: "Dairy",
        application: " Ice Creams, Frozen Yogurts, and Cheese",
      },
      {
        product: "Calcium Caseinate",
        industry: "Bakery",
        application: "Biscuits, Cookies, Cakes, and Breads",
        span  : {
          product : 4
        }
      },
      {
        industry: "Meat Industry",
        application: "Sausages, Nuggets, and Burger Patties",
      },
      {
        industry: "Beverage",
        application: "Milkshakes",
      },
      {
        industry: "Food Supplement",
        application: "Infant Nutrition, Sport Nutrition, and Health Beverages",
      },
    ],
    fruits: false,
  },
  "Cheese Powder": {
    didYouKnow: "The delicious cheese making process is 10,000 years old!",
    banner: chess_banner,
    product: chess,
    description: [
      "Drytech showcases a wide portfolio of cheese powders ranging from strong cheesy to mild, milky and sweet profiles. These are made by spray drying of quality Cheddar Cheese and ensures that the taste and flavor profile remains intact. Our cheese powder leaves a typical lingering mouth feel which will leave you with a wholesome experience of cheese. These cheese powders can be used in the industry segments that deals with convenience foods, seasoning and bakery.",
    ],
    table: [
      {
        product: "Cheese Powder",
        industry: "Convenience Foods",
        application:
          "Savoury Snacks, Extruded Snacks, Wafer, Chips dusting, and Instant Soup Premixes",
          span  : {
            product : 3
          }
      },
      {
        industry: "Seasoning",
        application: "Culinary Seasoning Mixes",
      },
      {
        industry: "Bakery",
        application: "Biscuits, Crackers, and Cheese Cakes etc.",
      },
    ],
    fruits: false,
  },
  "Hydrolyzed Vegetable Protein": {
    didYouKnow:
      "Hydrolyzed vegetable protein (HVP) is produced by boiling foods in hydrochloric acid and then neutralizing the solution with sodium hydroxide.",
    banner: hvp_banner,
    product: hvp,
    description: [
      "Drytech’s HVP powders can be used to enhance the flavor of several food formulations by breaking down the vegetable proteins into their basic amino acids, resulting in covering as much as 20% glutamate in your food product. Our HVP powder is manufactured taking into consideration the natural nutrients of vegetables, its longer shelf life, precise pH value and accurate composition. It is widely used in the industry segments that deals with umami flavored products like oriental cuisines, meaty flavor seasonings and also in snack seasoning.",
    ],
    table: [
      {
        span  : {
          product : 3
        },
        product: "Hydrolyzed Vegetable Protein",
        industry: "Convenience Foods",
        application:
          "Instant Gravy Mixes, Soup Premixes, and Vermicelli/ Instant Noodle Seasoning",
      },
      {
        industry: "Snacks",
        application: "Extruded Snacks Seasoning ",
      },
      {
        industry: "Umami Flavor",
        application:
          "Oriental Cuisines, Meaty Flavour Seasoning, Breading ,and Batters",
      },
    ],
    fruits: false,
  },
  "Non Dairy Creamer": {
    didYouKnow:
      "Non-dairy creamer is considered as the best substitute of milk by Vegans.",
    banner: non_dairy_banner,
    product: non_dairy,
    description: [
      "Drytech offers finest quality spray dried Non-Dairy Creamers with wide range of fats from different fat source formulations to meet customer’s diverse requirements. Available in both flavored & non- flavored variants, Our Non Dairy Creamers are specially developed on basis of specific applications such as coffee, tea, smoothies, shakes, nutritional beverages, Infant food formulas, bakery products, fat-filled milk powders, seasoning, convenience foods, frozen desserts and many more.",
    ],
    table: [
      {
        span  : {
          product : 5
        },
        product: "Non Dairy Creamer",
        industry: "Beverage",
        application: "Coffee & Tea Premixes, Shakes, and Drinks",
      },
      {
        industry: "Bakery",
        application: "Biscuits, Cookies, and Cakes",
      },
      {
        industry: "Convenience Foods",
        application:
          "Premixes for Cakes, Desserts, Soups, Whipped Creams, and Indian Sweets",
      },
      {
        industry: "Special Food",
        application:
          "Fat Filled Milk Powders or Instant Fat Filled Powders ,Infant Food Formula Mixes, and Lactose-Free Foods",
      },
      {
        industry: "Seasoning",
        application: "Base for Creamy Soups and Seasoning",
      },
    ],
    fruits: false,
  },
  "Beverage Whitener": {
    didYouKnow: "Beverage Whitener comprises of less than about 2% fats",
    banner: beverage_banner,
    product: beverage,
    description: [
      "Drytech produces a free-flowing Beverage Whitener that comes in the form of spray dried powder. Our Beverage Whitener can be used as a substitute to milk as it enhances the flavor profile and texture of your beverage with good solubility and stability. Drytech’s Beverage Whiteners can be used in the industry segments that deals with beverage, bakery and convenience foods.",
    ],
    table: [
      {
        span  : {
          product : 3
        },
        product: "Beverage Whitener",
        industry: "Beverage",
        application: "Coffee & Tea Premixes, Shakes, and Drinks",
      },
      {
        industry: "Bakery",
        application: "Biscuits, Cookies, and Cakes",
      },
      {
        industry: "Convenience Foods",
        application:
          "Premixes for Cakes, Desserts, Soups, Whipped Creams, and Indian Sweets",
      },
    ],
    fruits: false,
  },
  "Beetroot Powder": {
    didYouKnow:
      "Caramel is 1.5 times sweeter than table sugar and it has much lower Glycaemic Index.",
    banner: beetroots_banner,
    product: beetroot,
    description: [
      "At Drytech, we select the best quality raw beetroots to get premium quality beetroot natural color. Our stringent process control enables us to deliver you the best quality beetroot powder that generates beautiful and vibrant shades of pink and red in your food products. Our Beetroot powder is water soluble and can be used in the industry segments dealing with dairy products, savory and beverages.",
    ],
    table: [
      {
        span  : {
          product : 3
        },
        product: "Beetroot Powder",
        industry: "Dairy Product",
        application: "Milkshake, Ice Creams, and Frozen Yogurt",
      },
      {
        industry: "Savoury",
        application: "Soups, Sauces, Dips, and Salad Dressing, Seasoning",
      },
      {
        industry: "Beverages",
        application: "Premixes, Still Beverages",
      },
    ],
    fruits: false,
  },
  "Caramel Color": {
    didYouKnow:
      "Caramel is 1.5 times sweeter than table sugar and it has much lower Glycaemic Index.",
    banner: caramel_banner,
    product: caramel,
    description: [
      "Caramel Powder is a versatile and most widely consumed natural food color. Drytech manufactures high quality Caramel Powder for your food products. Our Caramel powder has a pleasant caramel aroma and can be used in the industry segments dealing with confectionery, bakery, beverages and savory products.",
    ],
    table: [
      {
        span  : {
          product : 4
        },
        product: "Caramel Powder",
        industry: "Savoury",
        application: "Soups, Sauces, Dips, and Salad Dressing, Seasoning",
      },
      {
        industry: "Bakery",
        application: "Biscuits, Cakes, Cookies, and Cakes",
      },
      {
        industry: "Confectionery",
        application: "Candies, and Chocolates",
      },
      {
        industry: "Beverages",
        application: "Premixes, Still Beverages, and Carbonated Drinks",
      },
    ],
    fruits: false,
  },
  // "Amla Powder": {
  //   banner: amla_banner,
  //   product: amla,
  //   description: [
  //     "Drytech’s Amla or Indian Gooseberry powder has typical Amla sourness with a salty note to it. It has minimum 15% tannic content. Amla powder has rich antioxidant properties and is easily dispersible in water. It has applications in nutraceutical products and Ayurveda formulations."],
  //   table: [
  //     {
  //       product: "Spray Dried Amla Powder",
  //       industry: "Nutraceutical",
  //       application: "Nutrition and vitamin supplements",
  //     }
  //   ],
  //   fruits: false,
  // },
  "Lime Powder": {
    banner: lime_banner,
    product: lime,
    description: [
      "Drytech’s Lime Powder has a taste of natural lime with typical sour and zesty flavor. Lime powder when added to beverages at the levels of 5-10% eliminates the usage of lime flavor in it. Our Lime powder can be used in the industry segments that deals with convenience food like instant beverages and Ice-cream premixes.",
    ],
    table: [
      {
        span  : {
          product : 2
        },
        product: "Lime Powder",
        industry: "Convenience Foods	",
        application: "Ready to Eat & Ready to Cook Products",
      },
      {
        product: "",
        industry: "Snacks",
        application: "Extruded Snacks Seasoning & Dusting",
      },
    ],
    fruits: false,
  },
};

// function split(params) {
//   let data = Object.values(data_details);
//   let keys = Object.keys(data_details);
//   data = data.map((row) => {
//     let table = row.table;
//     let i = 0;
//     let newTable = [];

//     while (true) {
//       if (
//         table.industry[i] === undefined &&
//         table.application[i] === undefined &&
//         table.product[i] === undefined
//       )
//         break;

//       newTable.push({
//         product: table.product[i],
//         industry: table.industry[i],
//         application: table.application[i],
//       });

//       i++;
//     }

//     row.table = [...newTable];
//     return row;
//   });
//   let newOBJ = {};
//   keys.map((row, i) => {
//     Object.assign(newOBJ, { [row]: data[i] });
//   });

//   console.log(newOBJ);
// }

// split();
